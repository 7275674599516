.mobile_content .fixture_dashboard_content {
    padding:5px;
}

.column_mobile_dashboard .MuiAppBar-root {
    box-shadow:none !important;
}

.mobile_content .MuiInputBase-input {
    padding: 16.5px 14px;
    padding-top: 16.5px;
    padding-right: 14px;
    padding-bottom: 16.5px;
    padding-left: 14px;
}

.mobile_league_selection {
    color:white !important;
    width:95%;
    margin-left:auto;
    margin-right:auto;
    display: block;
    border:0.5px solid white;
    border-radius: 5px;
    margin: 10px;
    text-align: center;
}

.mobile_league_selection li {
    padding:10px;
}

.mobile_navigation_list li {
    padding:5px;
}

ul option {
    padding:10px;
    border-top:1px solid rgb(231, 231, 231);
}

ul option:not(first-child) {
    border-top:1px solid rgb(231, 231, 231);
}
