.player-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    position: relative;
    width:100%;
}

.player-container .player-name {
    text-align: left;
    font-weight: 500;
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.player-container img.drawer-player-image {
    width: 46px;
    height: 46px;
    border: 1px solid #cccccc;
    border-radius: 50%;
    box-shadow: 0px 0px 0px 1px rgb(140 140 140/.2);
}

.player-container .player-details {
    display: flex;
    flex-direction: column;
}

.player-container img.drawer-team-logo {
    width: 20px;
    height: 20px;
    margin: 0 auto;
    position: relative;
    margin-right: 6px;
    top: -3px;
}

.drawer-statistics-table td {
    text-align: left;
    font-weight: 400;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow-x: clip;
    white-space: nowrap;
    min-width: 0px;
    font-size: 16px !important;
}

.stat-table-container .stat-name {
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    text-overflow: ellipsis;
    overflow-x: clip;
    white-space: nowrap;
    min-width: 0px;
}

.player-container .player-rating {
    padding: 3px 4px 2px 4px;
    font-size: 20px;
    border-radius: 5px;
    color: white;
    font-weight: 500;
    margin-left: auto;
    position: relative;
    box-shadow: 0px 0px 0px 1px rgb(140 140 140 / .2);
    align-self: center;
}

.formation-pill {
    font-weight: 500;
    margin-left: auto;
    padding: 5px;
    border-radius: 8px;
}

div.stat-group-heading {
    font-size: 16px;
    margin-bottom: 10px;
}

.stat-table-container .stat-group-statistic {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 30px;
    display: grid;
    align-items: center;
    justify-content: space-between;
    grid-auto-flow: column;
    font-size: 15px;
    padding: 24px;
}

.stat-table-container .stat-group-statistic .statistic-name {
    font-weight: 400;
    box-sizing: border-box;
    font-size: 14px;
}   

.stat-table-container .stat-group-statistic .statistic-value {
    font-weight: 400;
    font-size: 14px;
}

.drawer-statistics-table .statistics-heading {
    padding: 10px;
    background-color: rgb(245, 246, 255);
    width: 100%;
    text-align: left;
    font-size: 16px;
    font-weight: 700;
}

.drawer-statistics-table .statistics-category .statistics-container {
    display: flex;
    justify-content: space-between;
    height: 44px;
}

.drawer-statistics-table .statistics-category .statistics-container .statistic-name {
    font-size: 14px;
}

.drawer-statistics-table .statistics-category .statistics-container .statistic-value {
    font-size: 14px;
}
