.players_dashboard .column_player_selection_search {
    position:relative;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 14px;
    padding-bottom: 6px;
}

.players_dashboard .column_player_selection {
    padding-left:0;
    padding-right:0;
}

.players_dashboard .column_player_selection ul.list_player_Selection {
    list-style: none;
    padding-left:0;
}

.players_dashboard_pagnation {
  border-bottom: 1px solid rgba(34, 34, 38, 0.15);
  padding-top: 5px;
  padding-bottom: 5px;
}

.players_dashboard .column_player_selection ul.list_player_Selection li img.image_player_profile {
  width: 50px;
  aspect-ratio: auto 50 / 50;
  height: 50px;
  border-radius: 50%;
  border: 1px solid rgba(34, 34, 38, 0.15);
}

.players_dashboard .column_player_selection ul.list_player_Selection li img.image_team_logo {
  /* width: 24px;
  aspect-ratio: auto 24 / 24;
  height: 24px;
  margin-right:5px; */

  aspect-ratio: auto 15 / 15;
  width: 17px;
  height: 17px;
  top: 3px;
  border-radius: 50%;
  position: relative;
  margin-right: 3px;

}

.players_dashboard .column_player_selection ul.list_player_Selection li div.player_details {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex-grow: 1;
  margin-left: 16px;
}

.players_dashboard .column_player_selection ul.list_player_Selection li div {
  display:flex;
}

.players_dashboard .column_player_selection ul.list_player_Selection li div.player_rating {
  width:30px;
  height:30px;
  border-radius: 4px;
  text-align: center;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
}

.players_dashboard .column_player_selection ul.list_player_Selection li div.player_rating span.player_rating {
    color: rgb(255, 255, 255);
    text-align: left;
    font-weight: 700;
    font-size: 12px;
    text-align: center;
}

.players_dashboard .column_player_selection ul.list_player_Selection span.player_name {
  display: flex;
  color: rgb(34, 34, 38);
  text-align: left;
  font-weight: 500;
  font-size: 14px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.players_dashboard .column_player_selection ul.list_player_Selection span.team_name {
  margin-left: 4px;
    color: rgb(84 84 84);
    text-align: left;
    font-weight: 500;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-top: 5px;
}

.list_item_player_selection {
    padding-left: 15px;
    padding-right: 15px;
    padding-top:5px;
    padding-bottom: 5px;
}

.list_item_player_selection .col:not(.default_height), .list_item_player_selection .row:not(.default_height) {
    height:100%;
}

.players_dashboard .column_player_selection ul.list_player_Selection li.list_item_player_selection img.player_image {
    width: 40px;
    margin-left:auto;
    margin-right: auto;
    display: block;
    background-color:white;
    border-radius: 50%;
    border: 1px solid #cdcdcd;
    box-shadow: 0px 0px 0px 1px rgb(140 140 140/.2);
}

.players_dashboard .column_player_selection ul.list_player_Selection li.list_item_player_selection img.player_team_logo {
    width: 40px;
    position: absolute;
    top: 85px;
    left: 150px;
}

.players_dashboard .column_player_selection ul.list_player_Selection li.list_item_player_selection .column_player_national_flag {
    padding:10px;
}

.players_dashboard .column_player_selection ul.list_player_Selection li.list_item_player_selection .column_player_national_flag img.player_country_flag {
    width: 42px;
}

.players_dashboard .column_player_selection ul.list_player_Selection li.list_item_player_selection .column_player_selection_player_details {
    padding:0;
}

.players_dashboard .column_player_selection ul.list_player_Selection li.list_item_player_selection .column_player_selection_player_details label {
    margin-left: auto;
    margin-right:auto;
    text-align: center;
    display:block;
    width:100%;
}

.players_dashboard .column_player_selection ul.list_player_Selection li.list_item_player_selection .column_player_selection_player_details label.player_position {
    display: block;
    font-weight: 500;
    color: #676767;
}

.players_dashboard .column_player_selection ul.list_player_Selection li.list_item_player_selection .column_player_selection_player_details label.player_country {
    display:block;
    color: #ababab;
    font-size: 15px;
    font-weight: 500;
}

.players_dashboard .row_player_selection_details {
    height:100%;
    padding-top: 10px;
    padding-bottom: 10px;
}


/* UNUSED */

.no-suggestions {
    color: #999;
    padding: 0.5rem;
  }
  
  .suggestions {
    border: 1px solid #999;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 500px;
    overflow-y: auto;
    padding-left: 0;
    width: 100%;
    height: auto;
    position: absolute;
    background-color: white;
    z-index:1100;
  }
  
  .suggestions li {
    padding: 1.5rem;
  }
  
  .suggestions li.suggestion-active {
    background-color: #08679b;
    color: #ffffff;
    cursor: pointer;
    font-weight: 700;
  }
  
  .suggestions li:hover {
    background-color: #282828;
    color: #ffffff;
    cursor: pointer;
    font-weight: 700;
  }
  
  .suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #999;
  }