.MuiAppBar-root:not(.fixture-navigation) {
    background-color: #151e31 !important;
}

.br-15 {
    border-radius: 15px !important;
}

.mobile_content {
    padding-bottom: 35px;
    height:100%;
}

.center_content {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.vertically_align_children {
    display: flex;
    justify-content: center;
    align-items: center;
}

.widget_fade_in {
    -webkit-animation: fadein 1s; /* Safari, Chrome and Opera > 12.1 */
       -moz-animation: fadein 1s; /* Firefox < 16 */
        -ms-animation: fadein 1s; /* Internet Explorer */
         -o-animation: fadein 1s; /* Opera < 12.1 */
            animation: fadein 1s;
}

@keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Internet Explorer */
@-ms-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
    from { opacity: 0; }
    to   { opacity: 1; }
}

ul {
    margin-bottom: 0;
}

.align-text-center {
    text-align: center;
}

.elipsis_text_wrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ul_breadcrumb_navigation {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    list-style: none;
    margin: 0px;
    max-width: 100%;
    box-sizing: border-box;
    overflow-x: hidden;
    padding: 12px 16px;
    width:100%;
}

.ul_breadcrumb_navigation li:first-child:hover {
    cursor: pointer;
    color:#1976d2;
}

.ul_breadcrumb_navigation li {
    flex: 0 0 auto;
    max-width: 20ch;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: rgba(34, 34, 38, 1);
    font-weight: 500;
}

.ul_breadcrumb_navigation li:last-child {
    flex: 1 1 auto;
    max-width: unset;
}

.ul_breadcrumb_navigation li:not(:first-child):before {
    display: inline-block;
    content: "";
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 3px 0px 3px 5px;
    border-color: transparent transparent transparent rgba(34, 34, 38, 0.45);
    margin: 1px 5px;
}

@media (max-width: 850px) { 

    .column_mobile_dashboard {
        padding: 45px 0px 58px 0px;
    }
    
    .banner-logo-top {
        width: 180px;
        height: 38px;
    }

}

@media (max-width: 800px) { 

    .compact-hidden {
        display:none;
    }

    .league-table tbody td.td-club-name {
        min-width: 200px;
    }

    .league-table td.td-club-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .league-table {
        font-size: 13px;
    }

}

.loading_spinner {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 40px;
    top: 10%;
    margin-top: auto;
    margin-bottom: auto;
    position: relative;
}

.no_padding {
    padding: 0;
}

.information_label {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    background-color: rgb(255 255 255);
    margin-top: 10px;
    margin-bottom: 20px;
    color: #000000;
    padding: 15px;
    border-radius: 5px;
    box-shadow: 0px 0px 0px 1px rgb(140 140 140/.2);
}

.information_label svg {
    top: -2px;
    position: relative;
    margin-right: 5px;
}

.container_footer_information {
    color:White;
    padding-bottom: 20px;
    padding-top: 20px;
    border-top: 1px solid #2d2d2d;
}

.footer_copywrite {
    color: white;
    font-size: 12px;
}

.mobile_bottom_navigation {
    box-shadow: 0px 0px 0px 1px rgb(140 140 140/.2);
}

.mobile_bottom_navigation button {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    outline: 0px;
    border: 0px;
    margin: 0px;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    text-decoration: none;
}

.mobile_bottom_navigation .css-1ee5err-MuiButtonBase-root-MuiBottomNavigationAction-root.Mui-selected {
    padding-top: 6px;
    transition: 
        background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
        color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    color: #fff;
    background-image: linear-gradient(-180deg, #4c90d4 0%, #1976d2 100%);
}

.mobile_bottom_navigation button {
    background-image: linear-gradient(-180deg, #343131 0%, #252222 100%);
    box-sizing: border-box;
    color: #FFFFFF;
    display: flex;
    font-size: 16px;
    justify-content: center;
    text-decoration: none;
    width: 100%;
    border: 0;
    cursor: pointer;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
}

.mobile_bottom_navigation button:nth-child(2) {
    border-right:1px solid white;
    border-left: 1px solid white;
}

.no_side_padding {
    padding-left: 0;
    padding-right: 0;
}

/* Display Widgets */

.display_widget {
    background-color: white;
    box-shadow: 0px 0px 0px 1px rgb(140 140 140/.2);
    border-radius: 5px;
}

.padded_12_16_4 {
    padding: 12px 16px 4px;
}

.padded_8_16 {
    padding: 8px 16px;
}

.padded_6_6 {
    padding: 6px 6px;
}

.padded_4_4 {
    padding: 4px 4px;
}

.padded_4_6 {
    padding: 4px 4px;
}

.padded_8_8 {
    padding: 8px 8px;
}

.padded_8_12 {
    padding: 8px 12px;
}

.padded_8_14 {
    padding: 8px 14px;
}

.dashboard_widget {
    background-color: white;
    border-radius: 15px;
    box-shadow: 0px 0px 0px 1px rgb(140 140 140/.2);
}

.dashboard_widget .dashboard_widget_controls {
    padding: 10px 15px 5px 25px;
    border-radius: 4px;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.clickable:hover {
    cursor: pointer;
    background: #e7eaee;
    transition: color .28s cubic-bezier(.4,0,.2,1),background-color .28s cubic-bezier(.4,0,.2,1);
}

/* .MuiSelect-select, .MuiNativeSelect-select {
    padding: 12px 12px 8px !important;
} */

.widget_selection_dropdown::before {
    border-bottom: none !important;
}

.widget_selection_dropdown {
    border-radius: 5px !important;
}

.container_title {
    width:90%;
    margin-left: auto;
    margin-right: auto;
    font-weight: bold;
    margin:10px;
    text-align: center;
    font-size: 16px;
}

.section_header {
    background-color: rgba(229, 233, 239, 0.4);
    padding: 4px 8px;
    border-radius: 4px;
    width: 100%;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
}

.section_header.no_background {
    background-color:white;
}

.widget-scroller {
    overflow-y: auto;
    scrollbar-width: thin;
    scrollbar-color: #e3e3e3 #ffffff; /* Adjust colors as needed */
    
    /* Webkit (Chrome, Safari) scrollbar styling */
    &::-webkit-scrollbar {
        width: 5px;
        border-radius: 7px;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: #e3e3e3; /* Scrollbar thumb color */
    }
    
    &::-webkit-scrollbar-track {
        background-color: #ffffff; /* Scrollbar track color */
    }
}

.commentary_widget div.yellow_card {
    background-color: #d3ad02;
}

.commentary_widget div.red_card {
    background-color: #dd3c23;
}

.commentary_widget div.red_card, .commentary_widget div.yellow_card{
    width: 15px;
    height: 17px;
    border-radius: 2px;
    position: relative;
    top: 3px;
}

.commentary_widget .event_icon {
    position: relative;
    top: -2px;
}

.commentary_widget .sub_in {
    color: #1f8f1f;
    font-weight: 500;
}

.commentary_widget .sub_out {
    color: red;
}

/* Primary Dashboard Menu List */

.dashboard_menu {
    padding:0;
    background-color: #0D1E33;
    flex:unset;
    /* width: 250px;
    min-width: 250px; */
}

.dashboard_menu .dashboard_menu_seperator {
    border-top:1px solid #2E2930;
    margin-bottom: 5px;
    margin-top: 5px;
}

.dashboard_menu ul.dashboard_menu_list {
    list-style: none;
    padding: 5px;
    margin-bottom: 5px;
    padding-top:0;
    padding-right:5px;
}

.dashboard_menu ul.dashboard_menu_list li.dashboard_list_item {
    padding: 10px;
    color: white;
    padding-left:10px;
    padding-right:10px;
}

.dashboard_menu ul.dashboard_menu_list li.dashboard_list_item:hover {
    cursor:pointer;
    background-color: #203043;
    border-radius: 4px;
}

.dashboard_menu ul.dashboard_menu_list li.dashboard_list_item svg {
    top: -2px;
    position: relative;
}

/* Primary Dashboard List */

/* Primary Dashboard List Leagues */
.dashboard_menu ul.dashboard_menu_list_leagues {
    list-style: none;
    padding: 5px;
    margin-bottom: 5px;
    padding-right: 5px;
}

.dashboard_menu ul.dashboard_menu_list_leagues li.dashboard_league_list_item {
    list-style: none;
    padding: 10px;
    color: white;
    padding-left:10px;
    padding-right:10px;
    margin-bottom:2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.dashboard_menu ul.dashboard_menu_list_leagues li.dashboard_league_list_item:hover {
    cursor:pointer;
    background-color:rgb(0, 78, 158);
    border-radius: 4px;
}

.dashboard_menu ul.dashboard_menu_list_leagues li.dashboard_league_list_item_selected {
    background-color: rgb(0, 78, 158);
    border-radius:4px;
}

.dashboard_menu ul.dashboard_menu_list_leagues li.dashboard_league_list_item {
    top: -2px;
    position: relative;
}

.dashboard_menu ul.dashboard_menu_list_leagues li.dashboard_league_list_item span {
    margin-left: 10px;
}

/* Primary Dashboard List Leagues */

.dashboard_widget p.label_no_results_heading {
    color: #0D1E33;
    text-align: center;
    font-weight: 700; 
    font-size: 22px;
    letter-spacing: 0.1px;
}

.dashboard_widget .label_no_results_heading, .dashboard_widget .label_no_results_sub_heading {
    text-align: center;
}

.dashboard_widget .label_no_results_sub_heading {
    color: rgba(34, 34, 38, 0.45);
    text-align: center;
    font-weight: 400;
    font-size: 14px;
}
