@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap'); /* Importing Google Fonts */

.fixture-comments-container {
    border-radius: 8px; /* Rounded corners */
    padding: 10px; /* Padding around the container */
}

.fixture-comments-container .show-more-link {
    background-color: #e7e7e7;
    width:100%;
    text-align: center;
    border-radius: 5px;
}

.fixture-comments-container .show-more-link:hover {
    cursor: pointer;
    color:white;
}

.fixture-comments-container .fixture-commentary > .fixture-comment {
    display: flex;
    padding: 10px 0;
    border-bottom: 1px solid #eaeaea;
}

.fixture-comments-container .fixture-comment:last-child {
    border-bottom: none;
}

.fixture-comments-container .comment-header {
    display: flex;
    align-items: flex-start;
    width: 100%;
}

.fixture-comments-container .left-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
    border-right: 1px solid #cecece;
    min-width: 60.8px;
    padding: 5px 5px 5px 5px;
}

.fixture-comments-container .minute-container {
    display: flex;
    align-items: center;
    padding-bottom: 7px;
    text-align: center;
    width: 100%;
}

.fixture-comments-container .minute {
    color: #333;
    font-size: 14px;
    width: 100%;
}

.fixture-comments-container .image {
    border-radius: 16px;
    background-color: #e6e6e6;
    margin: 5px 5px 3px 5px;
    font-size: 12px !important;
    font-weight: 500;
}

.fixture-comments-container .image img {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    object-fit: cover;
    border: 2px solid #ccc;
    margin-right: 10px;
}

.fixture-comments-container .comment-body {
    color: #555;
    font-size: 14px;
    line-height: 1.4;
    flex: 1;
    padding: 5px 5px 5px 5px;
    margin-bottom: 3px;
}

.fixture-comments-container .comment-extra-minute {
    font-size: 8px;
}
