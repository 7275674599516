#root > div.container.website-wrapper {
    padding:0 !important;
    height: 100%;
}

.simplebar-scrollbar::before {
    background-color: rgb(229, 229, 229);
}

img.the_stat_man_dashboard_logo {
    width: 174px;
    /* margin-left: auto;
    margin-right: auto; */
    display: block;
    margin-top:15px;
    margin-bottom:15px;
}

.league-logo {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  
  .league-logo.fade-in {
    opacity: 1;
  }
  
  .league-logo.fade-out {
    opacity: 0;
  }

.column_no_side_padding {
    padding-left: 0;
    padding-right: 0;
}

.column_seperator {
    border-top:1px solid #2E2930;
}

.season_selection_container .MuiInputLabel-root, 
.season_selection_container .MuiSelect-select, 
.season_selection_container .MuiInputLabel-root:focus,
.season_selection_container .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
    color:white;
}

.season_selection_container .css-1d3z3hw-MuiOutlinedInput-notchedOutline,
.season_selection_container .css-1d3z3hw-MuiOutlinedInput-notchedOutline:hover,
.season_selection_container .css-1d3z3hw-MuiOutlinedInput-notchedOutline:focus {
    border: 2px solid white;
}
