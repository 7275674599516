.column_modal_statistics .statistics_table_container {
    margin-top:20px;
    margin-bottom:20px;
}

.column_modal_statistics .statistics_table_container:not(:last-child) {
    border-bottom:1px solid #eaeaea;
}

.modal_row_focused_statistics_profile {
    background-color: #f2f2f2;
    padding: 10px;
    border-radius: 5px;
    margin-bottom:30px;
    border: 1px solid #dddddd;
}

.modal_row_focused_statistics_profile img.image_player_profile {
    background-color: white;
    border-radius: 50%;
    width: 150px;
}

.modal_row_focused_statistics_profile img.image_team_logo {
    position: relative;
    width: 50px;
    left: -35px;
    top: 50px;
}

.table_lineup_statistics_focus tr td {
    border: 0;
    padding-top:6px;
}

.table_lineup_statistics_focus tr td.cell_player_stat_value {
    font-weight:500;
}

.table_lineup_statistics_focus tr td.cell_player_stat_value {
    text-align: right;
}

.image_statman_watermark {
    width:200px;
    float:right;
}

.modal_row_focused_statistics_profile .label_player_name {
    font-weight: bold;
    font-size: 20px;
    margin-top:auto;
    margin-bottom:auto;
    position:relative;
    top:25%;
}

.modal_row_focused_statistics_profile .label_player_rating {
    position: relative;
    bottom: 0;
    float: right;
    padding: 15px;
    border-radius: 5px;
    font-weight:bold;
    padding-left: 20px;
    padding-right: 20px;
}