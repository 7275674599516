

.row_league_teams {
    margin-left: auto;
    margin-right: auto;
    display: inline-flex;
}

.row_league_teams .column_league_team {
    background-color: white;
    color: #010E14;
    transition: color .28s cubic-bezier(.4,0,.2,1),background-color .28s cubic-bezier(.4,0,.2,1);
    padding-left: 20px;
    padding-right: 20px;
    box-shadow: 0px 0px 0px 1px rgb(140 140 140/.2);
    column-gap: 3px;
    border-radius: 3px;
} 

.row_league_teams .column_league_team:hover {
    background-color: rgb(0, 78, 158);
    color: rgb(255, 255, 255);
    cursor: pointer;
}

.row_league_teams {
    justify-content: center;
}

.row_league_teams .column_league_team .column_team_logo {
    padding-top: 10px;
    padding-bottom: 5px;
}

.row_league_teams .column_league_team .column_team_logo img {
    height: 64px;
    width: 64px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.row_league_teams .column_league_team .column_team_name span {
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 24px;
    margin-left: auto;
    margin-right: auto;
    display: block;
    text-align: center;
    padding-top:10px;
    padding-bottom: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media (min-width: 850px) { 

    .row_league_teams .column_league_team .column_team_name span {
        top: 25%;
        position: relative;
    }
    
  }