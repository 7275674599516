.dashboard_widget img.image_no_scheduled_fixtures {
    width: 50%;
    margin-left: auto;
    margin-right: auto;
    display: block;
    opacity: .2;
}

.container_livescore_controls {
    padding:15px;
    border-bottom: 1px solid rgba(34, 34, 38, 0.15);
}

.dashboard_widget div.event_competition_row {
    padding-left: 23px;
}

div.dashboard_widget div.event_competition_row {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 39px;
    left: 0px;
    width: 100%;
}

div.dashboard_widget div.event_row {
    left: 0px;
    width: 100%;
    display: block;
}

div.dashboard_widget div.event_details_row img.image_competition_logo {
    width: 24px;
    aspect-ratio: auto 24 / 24;
    height: 24px;
}

div.dashboard_widget div.event_details_row div.div_competition_labels {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex: 1 0;
    height: 48px;
    margin-left: 24px;
}

div.dashboard_widget div.event_details_row div.div_competition_labels > div {
    color: rgb(34, 34, 38);
    text-align: left;
    font-weight: 500;
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

div.dashboard_widget div.event_row .event_container {
    -webkit-box-align: center;
    position: relative;
    color: rgb(34, 34, 38);
}

div.dashboard_widget div.event_row .event_container .event_information {
    -webkit-box-align: center;
    position: relative;
    color: rgb(34, 34, 38);
    display: flex;
    padding: 0px 0px 3px 0px;
}

div.dashboard_widget div.event_row .event_container .event_state {
    margin-left: 8px;
    margin-right: 8px;
    text-align: center;
    flex: 0 0 56px;
    -webkit-box-flex: 0;
    align-content: center;
}

div.dashboard_widget div.event_row .event_container .event_time_status {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    font-weight: 400;
}

div.dashboard_widget div.event_row .event_container div.event_state div.event_date {
    color: #8f8f8f;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 400;
}

div.dashboard_widget div.event_row .event_container div.event_state .current_event_state span.event_status {
    width: 56px;
    color: #909090;
    text-align: center;
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

div.dashboard_widget div.event_row .event_container .event_column_divider {
    background-color: rgba(34, 34, 38, 0.15);
    height: 36px;
    margin-right: 8px;
    flex: 0 0 1px;
}

div.dashboard_widget div.event_row .event_container .event_column_teams {
    -webkit-box-flex: 1;
    flex-grow: 1;
    overflow: hidden;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    color: rgb(34, 34, 38);
}

div.dashboard_widget div.event_row .event_container .event_column_teams {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    flex-grow: 1;
}

div.dashboard_widget div.event_row .event_container .event_column_teams .live_score_teams {
    display: flex;
    flex-direction: column;
}

div.dashboard_widget div.event_row .event_container .event_column_teams .teams_container {
    display: flex;
    flex-direction: column; /* Stack spans vertically */
    align-items: left; /* Center align spans */
}

div.dashboard_widget div.event_row .event_container .event_column_teams .live_score_teams .team_name {
    color: #000000;
    text-align: left;
    font-weight: 400;
    font-size: 16px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

div.dashboard_widget div.event_row .event_container .event_column_teams .live_score_teams .team_name img {
    width: 18px;
    height: 18px;
    aspect-ratio: auto 18 / 18;
    margin-right: 5px;
    top: -1px;
    position: relative;
}

.competition_seperator {
    background-color: rgba(34, 34, 38, 0.15);
    height: 1px;
    margin: 5px 0px 0px;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
}

div.dashboard_widget div.event_row .event_container .event_column_scores {
    display: flex;
    align-items: flex-end;
    -webkit-box-pack: end;
    justify-content: flex-start;
    flex-direction: column;
    flex: 0 0 28px;
    width: 28px;
    margin-left: 4px;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    margin-right: 10px;
}

div.dashboard_widget div.event_row .event_container .event_column_scores .event_result {
    flex: 0 1 16px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
}

.score_container {
    display: flex;
    flex-direction: column; /* Stack spans vertically */
    align-items: center; /* Center align spans */
}

div.dashboard_widget div.event_row .event_container .event_column_scores .event_result span {
    min-width: 16px;
    color: inherit;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
}

.status_live, .status_postponed {
    color: rgb(203, 24, 24) !important;
}

.live-pulse {
    background-color: #4caf50;
    color: white !important;
    padding: 5px 10px;
    border-radius: 8px;
    text-transform: uppercase;
    box-shadow: 0 0 5px #4aac54;
    animation: pulse 1.5s infinite;
  }

  @keyframes pulse {
    0% {
      box-shadow: 0 0 0 0 #74de7f;
    }
    50% {
      box-shadow: 0 0 0 8px rgba(0, 255, 0, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(0, 255, 0, 0);
    }
  }

  .full-time-status {
    background-color: #e4e4e4;
    border-radius: 10px;
    border: 1px solid #d6d6d6;
  }