.fixture_dashboard_content {
    padding-bottom: 30px;
}

.row-match-heading {
    margin-bottom: 25px;
}

.row-match-heading .col-match-description {
    font-size: 18px;
    font-weight: 500;
}

.row-match-heading .col_match_compeition_logo {
    padding:0;
    width: auto;
}

.row-match-heading .col_match_compeition_logo img {
    width:45px;
}

.row-match-heading .col-match-time {
    font-size: 13px;
}

.column_match_goals svg {
    top: 4px;
    position: relative;
}

.column_match_goals .column_player_scorer:hover {
    cursor: pointer;
    text-decoration: underline 2px solid black;
}

.row-match-information {
    margin-bottom: 20px;
}

.row-match-information .row-match-teams .col-match-score {
    font-size: 35px;
    font-weight: 500;
    color: rgb(29, 29, 29);
    padding-top:15px;
    text-align: center;
    flex:1 0 64px;
}

.row-match-information .row-match-teams .col-match-score .row:last-child {
    font-size: 18px;
}

.row-match-information .row-match-teams img.image_fixture_team_logo {
    width: 70px;
    margin-left:auto;
    margin-right:auto;
    display: block;
}

.row-match-information .row-match-teams img.image_fixture_team_logo:hover {
    opacity: .6;
    cursor: pointer;
}

.home-page-chip-heading {
    width: 100%;
    height: 40px;
    font-size: 16px;
    border: 1px solid #cdcdcd;
    margin-bottom: 5px !important;
}

.row_match_events {
    font-size:13px;
    padding:10px;
}

.row_match_events .row_match_event {
    color: white !important;
    margin-bottom:5px;
    background-color: #f3f3f3;
    padding: 10px;
    border:1px solid #e9e9e9
}

.row_match_events .row_match_event .col_match_event {
    color: white !important;
}

.row_match_events .row_match_event .col_match_event .row:last-child .col {
    padding-top:3px;
}

.row_match_events .row_match_event .col_match_event .row .col {
    padding:10px;
}

.row_match_events .row_match_event.row_home_goal .col_match_event  {
    background-color: #0046A8;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 9px 16px, rgba(0, 0, 0, 0.32) 0px 2px 2px;
}

.row_match_events .row_match_event.row_away_goal .col_match_event {
    background-color:#1d1d1d;
    box-shadow: rgba(0, 0, 0, 0.18) 0px 9px 16px, rgba(0, 0, 0, 0.32) 0px 2px 2px;
}

.row_match_events .row_match_event .col_match_event .column_assister,
.row_match_events .row_match_event .col_match_event .column_goal_type {
    width:100%;
    font-size: 12px;
    padding-top: 4px;
    font-weight: 300;
}

.row_team_lineups .col_team_lineup {
    font-size:13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 500;
}

.row_team_lineups .col_team_lineup .col_lineup_player {
    padding-top:5px;
    padding-bottom: 5px;
    text-overflow: ellipsis;
    min-width: 40px;
    overflow: hidden;
    background-color: #f3f3f3;
    border: 1px solid white;
}

.row_team_lineups .col_team_lineup .col_lineup_heading {
    padding-top:5px;
    padding-bottom: 5px;
    text-overflow: ellipsis;
    min-width: 40px;
    overflow: hidden;
    background-color: #e9e9e9;
    border: 1px solid white;
    text-align: center;
    font-weight:500;
}

.row_team_lineups .col_team_lineup .col_lineup_player img {
    width:30px;
    margin-left:10px;
    margin-right:10px;
}

.row_team_lineups .col_team_lineup div.player_number {
    margin-right: 15px;
}

.row_team_lineups .col_team_lineup div.player_number {
    width: 25px;
    display: inline-block;
    font-weight: 500;
    text-align: center;
    background-color: #d8d8d8;
    border-radius: 6px;
    outline: 1px solid #e9e9e9;
}

.substitute_divider {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    border-bottom: 1px solid #bdbdbd;
}

.fixture_event_stepper {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.fixture_event_stepper img {
    width:20px;
    margin-right:10px;
}

.fixture_event_stepper .event_minute {
    margin-right: 10px;
}

.fixture_event_stepper .MuiTypography-body1 {
    font-size: 12px;
    font-weight: 400;
    line-height: 2.5;
    letter-spacing: 0.00938em;
}

.fixture_event_stepper .MuiStepper-root {
    width:50%;
    margin-left:auto;
    margin-right:auto;
}

.col_team_lineup .team_badge {
    width:70px;
}

.col_team_lineup .label_team_name {
    float: left;
    display: inline;
    width: 50%;
    margin-top: auto;
    margin-bottom: auto;
    font-size: 17px;
    margin-left:10px;
    padding:0;
}

.pitch {
    border-radius: 12px;
}

.pitch .lines {
    padding-top: 15px;
    padding-bottom: 15px;
}

.pitch .team {
    padding-top: 5px;
    padding-bottom: 20px;
}

.pitch .team:first-child {
    padding-right: 10px;
}

.pitch .team.away {
    padding-left: 10px;
}

.pitch .player-view .clickable:hover {
    opacity: .6;
}

.pitch .player-view .player {
    padding: 15px;
    width:auto;
    background-color: white;
}

.pitch .player-view .player .name {
    -webkit-box-orient: vertical;
    font-feature-settings: "ss01";
    box-sizing: border-box;
    color: #000 !important;
    display: -webkit-box;
    font-family: GTWalsheim-Rg, GTWalsheim-Md, Rubik, system, Arial, sans-serif;
    font-size: 11px;
    font-weight: bold;
    height: 32px;
    hyphens: auto;
    line-height: 12px;
    overflow-wrap: break-word;
    text-align: center;
    top: 46px;
    width: 78px;
}

/* .pitch .player-view .player  */

.pitch .card_status {
    position: absolute;
}

.pitch .card_status .card_status {
    width: 10px;
    height: 13px;
    left: 13px;
    position: relative;
    border-radius: 2px;
    top: -19px;
}

.pitch .goal_display {
    color: #fff;
    left: -18px;
    position: relative;
    top: 16px;
}

.pitch .player_rating {
    color: #fff;
    font-weight: 500;
    font-size: 11px;
    height: 16px;
    left: 32px;
    position: absolute;
    top: 22px;
    vertical-align: top;
    width: 22px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    text-align: center;
    justify-content: center;
    display: flex;
}

.pitch .goal_display svg {
    background-color: #fff;
    border-radius: 50%;
    color: #000;
    font-size: 18px;
    margin-right: 0px;
    position: relative;
    top: -11px;
    left: -3px;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
}

.pitch .team .player-view .player, .pitch .away .player-view .player {
    height: 40px;
    width: 40px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 
                0px 1px 1px 0px rgba(0, 0, 0, 0.14), 
                0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    background-color: white !important;
    position: relative;
    border: 2px solid #ffffff;
}

.team .lines .line {
    justify-content: space-evenly !important;
}

.pitch .player-view .player.home_team {
    /* background-image: url(../../../images/home_kit.svg); */
    background-repeat: no-repeat;
}

.pitch .away .player-view .player.away_team {
    /* background-image: url(../../../images/away_kit.svg); */
    background-repeat: no-repeat;
}

/* Home Team */

.pitch .player-view .player.home_team.yellow_carded {
    /* background-image: url(../../../images/home_kit_yellow_card.svg); */
    background-repeat: no-repeat;
}

.pitch .player-view .player.home_team.yellow_red_carded {
    /* background-image: url(../../../images/home_kit_yellow_red_card.svg); */
    background-repeat: no-repeat;
}

.pitch .player-view .player.home_team.red_carded {
    /* background-image: url(../../../images/home_kit_red_card.svg); */
    background-repeat: no-repeat;
}

/* Home Team */

/* Away Team */

.pitch .away .player-view .player.away_team.yellow_carded {
    /* background-image: url(../../../images/away_kit_yellow_card.svg); */
    background-repeat: no-repeat;
}

.pitch .away .player-view .player.away_team.yellow_red_carded {
    /* background-image: url(../../../images/away_kit_yellow_red_card.svg); */
    background-repeat: no-repeat;
}

.pitch .away .player-view .player.away_team.red_carded {
    /* background-image: url(../../../images/away_kit_red_card.svg); */
    background-repeat: no-repeat;
}

/* Away Team */

.pitch .player-view .player .number {
    font-weight: 500;
    left:20px !important;
    display: none;
}

@media (max-width:990px) {
    
    .pitch .player-view .player .number {

        font-size: 8px;
        
    }

    .MuiAlert-standardInfo {

        font-size: 10px !important;
        
    }

}

.pitch .player-view:hover {
    cursor:pointer;
}

.row_lineup_tab > .row {
    padding-left: 40px;
    padding-right: 40px;
}

.column_fixture_lineup {
    padding-left: 0;
    padding-right: 0;
}

.column_fixture_lineup .column_lineup_team_information {
    padding-top:10px;
    padding-bottom: 10px;
    padding-left: 0;
    padding-right: 0;
}

.column_fixture_lineup .column_lineup_team_information *:hover {
    cursor: pointer;
    text-decoration: 2px underline black;
}

.column_fixture_lineup img.image_lineup_team_logo {
    width: 40px;
    margin-left: auto;
    float:left;
    display: inline;
    margin-right: 20px;
}

.column_fixture_lineup label.label_line_team_name {
    font-weight: 500;
    position: relative;
    top: 9px;
}

.column_fixture_lineup .underlying_list_team_lineup {
    list-style: none;
    padding:0;
}

.column_fixture_lineup .underlying_list_team_lineup .list_item_lineup_player {
    border-bottom: 1px solid #ebebeb;
    padding: 5px 20px 5px 3px;
}

.column_team_lineup:last-child {
    margin-bottom:10px;
}

.column_fixture_lineup .underlying_list_team_lineup .list_item_lineup_player label.label_player_number {
    margin-right: 6px;
    margin-left: 6px;
}

.column_fixture_lineup .underlying_list_team_lineup .list_item_lineup_player img {
    width: 40px;
    height: 40px;
    margin-right: 5px;
    background-color: #b6b6b6;;
    border-radius: 50%;
    margin-left: 10px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px,
                rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, 
                rgba(0, 0, 0, 0.12) 0px 1px 3px 0px
}

.column_fixture_lineup .underlying_list_team_lineup .list_item_lineup_player label {
    font-size: 14px;
    font-weight: 400;
}

.underlying_list_team_lineup {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.list_item_lineup_player {
    display: flex;
    align-items: center;
    padding: 10px;
}

.player-image {
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.player-info {
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: space-between;
}

.label_player_name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #333;
    font-size: 16px;
    flex-grow: 1;
    margin-right: 10px;
}

.span_player_fixture_rating {
    text-align: center;
    color: #fff;
    width: 50px;
    padding: 5px; 
    font-weight: 400; 
    font-size: 14px; 
    border-radius: 6px;
    min-width: 37px;
    width: 37px;
    display: inline-block;
}

.table-lineup-statistics thead tr th:hover {
    cursor: pointer;
}

.table-lineup-statistics thead tr th:not(:first-child) {
    max-width: 100px;
    width: 100px;
}

.table-lineup-statistics thead tr th {
    position: relative;
    padding:8px;
    line-height: 15px;
    /* width: 100px;
    max-width: 100px; */
}

.table-lineup-statistics thead tr th:first-child {
    width:130px;
    max-width: 130px;
}

.table-lineup-statistics thead tr th svg {
    position: absolute;
    bottom: -7px;
    left: calc(50% - 7px);
}

.column_fixture_lineup .substitute_divider {
    padding: 10px;
    border-bottom: 1px solid #e7e7e7;
    margin-bottom: 15px;
}

.status_live {
    color:red;
    /* font-weight:bold; */
}

.status_live {
    color:red !important;
    font-weight:bold;
    /* animation:blinkingText 2.5s infinite !important; */
}

.blink {
    color:red !important;
    animation:blinkingText 2.5s infinite !important;
}

@keyframes blinkingText{
    0%{color: #ED1C24;}
    40% {color: transparent;}
    60%{color: #ED1C24;}
    90% {color: transparent;}
    100%{color: #ED1C24;}
}

@-webkit-keyframes status_live {  
    from { color: white; }
    to   { color: default; }
}

@keyframes status_live {  
    from { color: white; }
    to   { color: default; }
}

.fixture-event {
    display: flex;
    align-items: center;
  }
  
  .fixture-event.reverse, .fixture-event .info.reverse {
    flex-direction: row-reverse;
  }

  .fixture-event .info, .fixture-event .icon {
    display: flex;
    align-items: center;
    overflow: hidden;
    min-width: 25px;
  }

  .fixture-event .icon .yellow_card, .fixture-event .icon .red_card {
    width:17px;
    height:21px;
    border-radius: 2px;
    margin-left: 3px;
  }

  .fixture-event .icon .yellow_card {
    background-color: #d3ad02;
  }

  .fixture-event .icon .red_card {
    background-color: #dd3c23;
  }


  .fixture-event .minute-container:not(.reverse),
  .fixture-event .player-name,
  .fixture-event .description {
    margin-right: 10px;
  }

  
  .fixture-event .player-name span {
    font-weight: 400;
  }

.fixture-event .player-name span.sub_in {
    color:#1f8f1f;
}

.fixture-event .description span.sub_out {
    color: red;
}

  .fixture-event .player-name {
    color: black;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 400;
    line-height: 1.3;
    letter-spacing: -0.3px;
    font-size: 16px;
  }

  .fixture-event .minute-container .minute {
    font-weight: 500;
    color: #747474;
    font-size: 14px;
  }

  .fixture-event .minute-container:not(.reverse) {
    border-right: 1px solid #d9d9d9;
  }

  .fixture-event .minute-container.reverse {
    border-left: 1px solid #cacaca;
    padding-left: 6px;
  }

  .fixture-event .description.bold_description {
    font-weight: bold;
  }

    .fixture-event .description:not(.bold_description) {
        color: #979797;
        font-weight: 400;
        letter-spacing: -0.3px;
        font-size: 14px;
    }

    .fixture-event .description {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .event_period_separator span.additional_minutes {
        font-weight: 500;
        font-size: 11px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        display: block;
        margin-bottom: 15px;
    }

    .event_period_separator span svg {
        position: relative;
        top: -1px;
        margin-right: 6px;
    }

  .event_period_separator label {
    background-color: rgba(229, 233, 239, 0.4);
    padding: 4px 8px;
    border-radius: 4px;
    font-weight: 500;
    width: 100%;
    text-align: center;
    font-size: 12px;
    margin-top: 10px;
    margin-bottom:10px;
    text-transform: uppercase;
    background-color: #f5f6f8;
    border: 1px solid #ebebeb;
    border-radius: 10px;  
}

.info {
    display: flex;
    align-items: center; /* Vertically center items */
}

.minute-container {
    width: 36px; /* Adjust the width as needed */
    text-align: center; /* Align text to the right within the container */
}
  
div.events_team_indicator {
    background-color: rgba(229, 233, 239, 0.4);
    padding: 7px;
    border-radius: 5px;
}

  .events_team_indicator .events_team_logo {
    width: 26px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

.fixture_statistics_widget {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
}

.progress_row {
    display: flex;
    width: 100%;
    padding-bottom: 12px;
    padding-left: 8px;
    padding-right: 8px;
}

.progress_row .progress_component {
    gap: 16px;
    display:flex;
    padding-bottom: 12px;
    padding-left: 8px;
    padding-right: 8px;
}

.progress_row .progress_component .progress_bar {
        background-color: #ececec;
        height: 8px;
        width: 100%;
        border-radius: 8px;
    }

    .progress_row .progress_component .progress_bar .line {
        opacity: 1;
        border-radius: 8px;
        height:100%;
    }

.fixture_penalty_widget .fixture_penalty_container {
    display: flex;
    flex-direction: column;
    padding:14px 16px;
    padding-bottom:0;
}

.fixture_penalty_widget .fixture_penalty_container .team_penalties {
    display: flex;
    align-items: flex-start;
    flex-direction: row;
    background-color: #d8d8d866;
    border-radius: 16px;
}

.fixture_penalty_widget .fixture_penalty_container .penalty_outer_container {
    width:100%;
}

.fixture_penalty_widget .fixture_penalty_container .penalty_outer_container .team_penalties .logo_container {
    margin-left: 0px;
    margin-right: 16px;
}

.fixture_penalty_widget .fixture_penalty_container .penalty_outer_container .team_penalties .logo_container img.team_logo {
    width: 30px;
    height: 30px;
    border-radius: 50%;
}

.fixture_penalty_widget .fixture_penalty_container .penalty_outer_container .team_penalties .penalty_outcomes {
    display: flex;
    margin-top: 4px;
    margin-bottom: 4px;
}

.fixture_penalty_widget .fixture_penalty_container .penalty_outer_container .team_penalties .penalty_outcomes .penalty_scored {
    background-color: #15b168;
    height: 10px;
    width: 10px;
    margin-top: 4px;
    margin-bottom: 4px;
    border-radius: 50%;
    margin-left: 8px;
}

.fixture_penalty_widget .fixture_penalty_container .penalty_outer_container .team_penalties .penalty_outcomes .penalty_missed {
    background-color: #c7361f;
    height: 10px;
    width: 10px;
    margin-top: 4px;
    margin-bottom: 4px;
    border-radius: 50%;
    margin-left: 8px;
}

.fixture_penalty_widget .fixture_penalty_container .penalty_outer_container .team_penalties .penalty_total_container {
    margin: 4px 12px 4px 8px;
    -webkit-box-flex: 1;
    flex-grow: 1;
}

.fixture_penalty_widget .fixture_penalty_container .penalty_outer_container .team_penalties .penalty_total_container .total_label {    min-width: 20px;
    color: var(--on-surface-nLv1);
    text-align: right;
    font-size: 14px;
    font-weight: 500;
}

.fixture_penalty_widget .fixture_penalty_container .penalty_outer_container .penalty_scorers {
    flex-wrap: wrap;
    display: flex;
    margin-top: 2px;
    margin-left: 30px;
}

.fixture_penalty_widget .fixture_penalty_container .penalty_outer_container .penalty_scorer_scored {
    margin-right: 4px;
    color: #15b168;
    text-align: left;
    font-weight: 400;
    font-size: 12px;
}

.fixture_penalty_widget .fixture_penalty_container .penalty_outer_container .penalty_scorer_missed {
    margin-right: 4px;
    color: #c7361f;
    text-align: left;
    font-weight: 400;
    font-size: 12px;
}

.fixture_form_column:hover {
    cursor: pointer;
}

.fixture_form_column img:hover {

    border: 2px solid white;

}

.fixture_period_toggle_group.justify-left {
    justify-content: left;
}

.fixture_period_toggle_group button {
    border: 1px solid rgba(0, 0, 0, 0.12) !important;
    padding: 7px !important;
}

.fixture_period_toggle_group button:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
}

.fixture_period_toggle_group button:last-child {
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.fixture-outcome-won {
    color: #39b57a;
}

.fixture-outcome-draw {
    color: #848484;
}

.fixture-outcome-lost {
    color: #e35c47;
}

.highlight-card-title:hover {
    text-decoration: underline;
    cursor: pointer;
}