.insight_fixture_summary {
    border-bottom: none;
    transition: background-color 1s;
}

.insight_fixture_summary:hover, .insight_fixture_summary *:hover {
    cursor: pointer;
}

.insight_fixture_summary img.team_logo {
    width:50px;
    height:50px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.insight_fixture_summary .league_logo {
    width: 25px;
    height: 25px;
    margin-right: 8px;
}

.insight_fixture_summary label.team_name {
    font-weight: 500;
    text-align: center;
    font-size: 16px;
    width: 100%;
    margin-top: 5px;
}

.insight_fixture_summary .column_insight_fixture_summary_score span.fixture_score {
    font-size: 35px;
    font-weight: 500;
    margin-left: auto;
    margin-right: auto;
    display: block;
    text-align: center;
}

.insight_fixture_summary label.fixture_state {
    font-size: 16px;
    font-weight: 500;
    margin-left: auto;
    margin-right: auto;
    display: block;
    text-align: center;
    color: rgba(34, 34, 38, 0.45);
    margin-top: 5px;
}

.insight_fixture_summary .column_insight_fixture_summary_score {
    align-items: center;
    display: flex;
 }

 .insight_fixture_summary .difficulty_rating {
    font-size: 30px;
    font-weight: 500;
    margin-left: auto;
    margin-right: auto;
    display: block;
    text-align: center;
 }

.foul_insights img.player_image {
    width: 75px;
    height: 75px;
    background-color: white;
    border-radius: 50%;
    border: 1px solid #0d0715;
    border: 1px solid rgba(34, 34, 38, 0.15);
    border-radius: 50%; 
    align-items: center;
    display: flex;
}

.foul_insights img.home_team_logo, .foul_insights img.away_team_logo {
    width:35px;
    height: 35px;
}

.foul_insights img.away_team_logo {
    float:right;
}

.foul_insights .player_information {
    border-radius: 3px;
    height:80px;
    background-color: #0C2D48;
    color: white;
    box-shadow: 0px 0px 0px 1px rgb(140 140 140/.2);
}

.foul_insights .insight-0 {
    background-color: #f0f0f0;
}

.foul_insights .insight-1 {
    background-color: #ADD8E6;
}

.foul_insights .insight-2 {
    background-color: #8AC7DB;
}

.foul_insights .insight-3 {
    background-color: #67B7D1;
}

.foul_insights .insight-4 {
    background-color: #43A6C6;
}

.foul_insights .insight-5 {
    background-color: #338BA8;
}

.foul_insights .high-insight {
    background-color: #296E85;
}

.table_foul_insights {
    margin-bottom: 0;
}

.foul_insights table tbody tr td {
    text-align: center;
    font-weight: 500;
}

div.foul_insight_totals {
    /* padding: 10px;
    background-color: #4089a6;
    border-radius: 15px;
    height: 100px;
    position: relative; */
}

div.foul_insight_totals span.foul_insight_totals_description {
    margin-bottom: 2px;
    color: rgba(34, 34, 38, 0.45);
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
}

div.foul_insight_totals span.foul_insight_totals_value {
    text-align: center;
    font-size: 23px;
    font-weight: 500;
}

.difficulty_rating_column .recharts-wrapper {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.table_tooltip_statistics tbody tr td {
    padding:10px;
}

.table_tooltip_statistics tbody tr td:first-child {
    font-weight: 400;
}

.table_tooltip_statistics tbody tr td:last-child {
    font-weight: 500;
    text-align: center;
}