.rating_gradient_20   {background-color: #037ab0 !important;}
.rating_gradient_40   {background-color: #1dac04 !important;}
.rating_gradient_60   {background-color: #d3ad02 !important;}
.rating_gradient_80   {background-color: #e67a29 !important;}
.rating_gradient_100  {background-color: #c52b2b !important;}
.rating_gradient_unset {background-color: #a4a9b3 !important;}

.statistics_gradient_20   {background-color: #16B13D !important;}
.statistics_gradient_40   {background-color: #7BB11B !important;}
.statistics_gradient_60   {background-color: #B19719 !important;}
.statistics_gradient_80   {background-color: #B1671E !important;}
.statistics_gradient_100  {background-color: #B12B1D !important;}
.statistics_gradient_unset {background-color: #a4a9b3 !important;}

.gradient_0 {background: #037ab0;}

.gradient_5    {background: #57bb8a;}
.gradient_10    {background: #63b682;}
.gradient_15   {background: #73b87e;}
.gradient_20   {background: #84bb7b;}
.gradient_25   {background: #94bd77;}
.gradient_30   {background: #a4c073;}
.gradient_35   {background: #b0be6e;}
.gradient_40   {background: #c4c56d;}
.gradient_45   {background: #d4c86a;}
.gradient_50   {background: #e2c965;}
.gradient_55   {background: #f5ce62;}
.gradient_60   {background: #f3c563;}
.gradient_65   {background: #e9b861;}
.gradient_70   {background: #e6ad61;}
.gradient_75   {background: #ecac67;}
.gradient_80   {background: #e9a268;}
.gradient_85   {background: #e79a69;}
.gradient_90   {background: #e5926b;}
.gradient_95   {background: #e2886c;}
.gradient_100   {background: #dd776e;}
.gradient_unset  {background: #afafaf;}



.stat_80 {background: #037ab0;}
.stat_70 {background: #57bb8a;}
.stat_60   {background: #c4c56d;}
.stat_50   {background: #e9b861;}
.stat_40   {background: #dd776e;}
.stat_30   {background: #de4141;}