html, #root, .App {
    height:fit-content;
}

html, body, #root, .breakpoint__large-up, .row_primary_dashboard {
    height:100%;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f0f2f4 !important;
    /* background-color: #FDFCFF; */
}

.css-1824apa-MuiTabs-root {
    border-bottom: 0;
    /* background-color: #f3f5f9 !important; */
    background-color: white !important;
}

.css-10k7o5q-MuiButtonBase-root-MuiTab-root.Mui-selected {
    font-weight: 400 !important;
    background-color: rgb(0, 78, 158) !important;
    color: white !important;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
    max-width: 100%;
}

.center_display_block {
    margin-left: auto;
    margin-right: auto;
    display: block;
}

.MuiInputBase-input:hover {
    cursor: pointer;
}

.text_elipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.center_text {
    text-align: center;
}

.row {
    margin-right:0;
    margin-left:0;
}

a.underlined_link:hover {
    text-decoration:underline 2px !important;
}

a.underlined_link label:hover {
    text-decoration:underline 2px !important;
    cursor:pointer;
}

.div_match_details {
    text-align: justify;
    text-justify: inter-word;
    padding: 20px;
    background-color: rgb(241 241 241);
    font-size: 13px;
    color: #151515;
    font-weight: 400;
}

.div_match_details .match_details_title {
    font-size: 16px;
    font-weight: 500;
    margin-left: auto;
    margin-right: auto;
    display: block;
    margin-bottom: 10px;
    width: 200px;
    text-align: center;
}

.website-wrapper .card {
    border: 0;
}

.footer_information {
    font-size: 13px;
}

.statman-home-page {
    padding-bottom:20px;
}

.css-1jfgnjo-MuiPaper-root-MuiAppBar-root {
    top:-2px !important;
}

.search-box-rounded {
    border-radius:5.5rem;
    width: 97%;
    margin-right: auto;
    margin-left: auto;
  }

  .site-container-panel .MuiBox-root {
      padding:0;
  }

.pro-sidebar {
    z-index: 1101;
  }

  .navbar-close {
    float: right;
    padding: 0px;
    border: 2px solid #4f4f4f;
    margin: 8px;
    border-radius: 5px;
    background-color: black;
  }

  .navbar-close:hover {
    background-color: #4f4f4f;
  }

.btn-toggle {
    width: 32px;
    height: 32px;
    top: 50%;
    transform: translate(0, -50%);
    position: absolute;
}

    .btn-toggle:hover {
        cursor:pointer;
    }

.cursor-pointer:hover {
    cursor:pointer;
}

.card-selection  {
  padding-top:20px;
  text-align: center;
}

    img.card-selection-image {  
        margin-left:auto;
        margin-right: auto;
        width: 90%;
    }

    img.card-selection-image.team-selection-image {
        margin-left:auto;
        margin-right: auto;
        width: 75%;
    }
    .card-selection:hover {
        transform: scale(1.08);
        transition: transform 150ms ease-in-out;
    }

.profile-header {
    border-bottom: 8px solid #0046A8;
}

    .team-profile-header-img-container {
        width: 60px;
        height: 60px;
        padding: 0;
        display: inline;
        float: left;
        margin-bottom: 20px;
        margin-right: 15px;
    }

        .team-profile-header-img-container img {
            width: 60px;
            height: 60px;
        }

  .align-middle {
    top: 50%;
    transform: translate(0, -50%);
    position: relative;
    margin: 0;
  }

  .card {
    border: 0;
    box-shadow: 0px 0px 0px 1px rgb(140 140 140/.2);
    border-radius: 4px;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: initial;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0;
  }

  .card.profile-header {
    border-bottom: 8px solid #0046A8;
  }

a:not(.anchor-tag) {
    text-decoration: none !important;
    color: inherit;
}

.social_media_links {
    list-style: none;
    width: 100%;
    position: relative;
    top: 40%;
    margin: 0 auto;
    padding:0;
}

.social_media_links li 
{
    display:inline;
}

.social_media_links a {
    margin: 10px;
}

.site-container-panel .season-overview {
    border-radius: 0;
}

.pro-sidebar {
    border-right: 1px solid rgba(173, 173, 173, 0.2);
}

.pro-sidebar-layout .pro-sidebar-header {
    height: 58px;
}

.pro-sidebar-layout .pro-sidebar-header .navbar-close {
    margin-right: 15px;
}

.season-overview .season-overview-header {
    padding:10px;
    background-color:#0046A8;
    color:white;
    border-radius:0;
}

.season-overview .season-overview-header .season-overview-club-logo {
    width:30px;
    height:30px;
}

.season-overview .season-stat-overview {
    font-size:13px;
    margin-bottom:30px;
    background-color: #f4f4f4;
    border:white;
}

.season-overview .season-stat-overview tr td:first-child {
    font-weight: 500;
}

.player-profile-card {
    margin-top: 5px;
    margin-bottom:5px;
}

.player-profile-card .card-title {
    margin-bottom: .5rem;
}

.player-profile-card .card-body {
    padding:0;
}

.player-profile-card .player-card-picture {
    width: 50px;
    position: relative;
    display: inline;
    float: left;
    margin: 5px;
    background-color: whitesmoke;
    border: 1px solid #e9e9e9;
    border-radius: 50%;
}

.player-profile-card span {
    position: relative;
    display: inline;
    float: left;
    margin-left: 20px;
    font-weight: 400;
}

.player-profile-card .player-card-country {
    width: 40px;
    position: relative;
    display: inline;
    float: right;
    margin-right:10px;
    background-color: whitesmoke;
    border: 1px solid #e9e9e9;
}

.scrollable-container {
    overflow: auto;
    max-height: 1000px;
}

.player_leaderboard_table thead tr td:not(:first-child) {
    text-align: center;
}

.player_leaderboard_table tbody tr td:last-child {
    font-weight: bold;
}

.div_season_selector .btn_historical_season_Selector {
    float:right;
    color:white;
    background-color: #1d1d1d;
    outline:1px solid #1d1d1d;
    border:1px solid white;
}

.div_season_selector .btn_historical_season_Selector:hover {

    float:right;
    color:#1d1d1d;
    background-color: white;
    outline:1px solid #2b2a2a;
    border:1px solid white;
    cursor: pointer;

}

.no-horizontal-padding {
    padding-left:0;
    padding-right:0;
}

.table_player_breakdown tr:nth-child(odd) {
    background-color:#f5f5f5;
}

.span_player_scorer:hover {
    text-decoration: underline 2px;
    cursor: pointer;
}

.statman_rating_gradient {
    height:20px;
    background: linear-gradient(90deg, rgba(221,119,110,1) 0%, rgba(245,206,96,1) 50%, rgba(87,187,138,1) 100%);
}

.row-selection {
    width: 90%;
    margin-right: auto;
    margin-left: auto;
    max-width: 1350px;
}

.btn_show_more_less {
    max-width: 95%;
    border-radius: 3px;
    border: 0;
    font-size: 15px;
    color: rgb(34, 34, 38);
    padding-top: 5px;
    padding-bottom: 5px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
    background-color: #f7f7f7;
}

.btn_show_more_less:hover {
    color: #ffffff !important;
    background-color: #dddddd;
}