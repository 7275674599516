.stat-pack-value.insight-0 {
    background-color: #f0f0f0;
}

.stat-pack-value.insight-1 {
    background-color: #ADD8E6;
}

.stat-pack-value.insight-2 {
    background-color: #8AC7DB;
}

.stat-pack-value.insight-3 {
    background-color: #67B7D1;
}

.stat-pack-value.insight-4 {
    background-color: #43A6C6;
}

.stat-pack-value.insight-5 {
    background-color: #338BA8;
}

.stat-pack-value.insight-high {
    background-color: #296E85;
}