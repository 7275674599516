.teams_dashboard {
    padding:0;
}

.teams_dashboard .column_team_selection_search {
    position:relative;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 14px;
    padding-bottom: 6px;
}

.teams_dashboard_pagnation {
    border-bottom: 1px solid rgba(34, 34, 38, 0.15);
    padding-top: 5px;
    padding-bottom: 5px;
}

.teams_dashboard .column_team_selection {
    padding-left:0;
    padding-right:0;
}

.teams_dashboard .column_team_selection ul.list_team_selection {
    list-style: none;
    padding-left:0;
}

.teams_dashboard .column_team_selection ul.list_team_selection li.list_item_team_selection *:hover {
    cursor: pointer;
}

.teams_dashboard .column_team_selection ul.list_team_selection li img.image_team_logo {
    width: 40px;
    aspect-ratio: auto 40 / 40;
    height: 40px;
    
}

.teams_dashboard .column_team_selection ul.list_team_selection li div.team_details {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    -webkit-box-flex: 1;
    flex-grow: 1;
    margin-left: 16px;
}

.teams_dashboard .column_team_selection ul.list_team_selection li div {
    display:flex;
}

.teams_dashboard .column_team_selection ul.list_team_selection li.list_item_team_selection img.image_league_logo {
    aspect-ratio: auto 40 / 40;
    width: 40px;
    height: 40px;
    margin-top: auto;
    margin-bottom: auto;
    opacity: .7;
}

.teams_dashboard .column_team_selection ul.list_team_selection span.league_name {
    margin-left: 4px;
    color: rgb(84 84 84);
    text-align: left;
    font-weight: 500;
    font-size: 12px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    padding-top: 5px;
  }

  .teams_dashboard .column_team_selection ul.list_team_selection span.team_name {
    display: flex;
    color: rgb(34, 34, 38);
    text-align: left;
    font-weight: 500;
    font-size: 14px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 500;
  }

.teams_dashboard .column_team_selection ul.list_team_selection li.list_item_team_selection img.image_country_flag {
    aspect-ratio: auto 18 / 13;
    width: 18px;
    height: 13px;
    top: 3px;
    position: relative;
    margin-right: 3px;
}
  