#root > div.container.website-wrapper > header > div > div.container {
  padding:0;
}

#root > div.container.website-wrapper > header > div > div.title-bar-container {
  max-width: 100%;;
}

.no-side-padding {
  padding-left:0;
  padding-right:0;
}

.table * {
  border-bottom: 1px;
}

   @media (max-width: 640px) {

    #root > div.container.website-wrapper {
      padding-top: 65px;
    }

    .col-selection {
      padding:15px;
    }

  }

  @media (max-width:990px) {
    .mobile-hidden {

      display:none;

    }

    table .mobile-hidden {

      display:none;

    }

    .fixture_event_stepper .MuiStepper-root {
        width:100% !important;
        margin-left:auto !important;
        margin-right:auto !important;
    }

    .modal_row_focused_statistics_profile img.image_player_profile {
        background-color: white;
        border-radius: 50%;
        width: 70px !important;
        margin-left: 15px;
    }

    .modal_row_focused_statistics_profile .label_player_name {
      font-size:13px !important;
    }

    .pitch .player-view .player .name  {
      font-size:8px !important;
    }

    .col_team_lineup .team_badge {
      width:45px !important;
    }

  }
  
  @media (min-width: 600px) {

    #root > div.container.website-wrapper {
      padding-top: 70px;
    }

  }

   @media (max-width: 800px) { 

    #root > div.container.website-wrapper {
      max-width:100%;
    }

    .banner-logo-top {
      width: 210px;
    }

      .underlying_list_team_lineup .label_player_name {
          max-width: 128px; /* Adjust max width for smaller screens */
          display: block; /* Make it block-level to take full width */
          overflow: hidden; /* Hides overflowed content */
          white-space: nowrap; /* Prevents the text from wrapping */
          text-overflow: ellipsis; /* Adds ellipsis (...) for overflowed text */
      }
  }

  @media (min-width: 700px) { 

    #root > div.container.website-wrapper {
      padding-top: 80px;
    }
    
    .banner-logo-top {
      width:210px;
    }

  }

  @media (min-width : 1000px) {
    
    #root > div:nth-child(2).container:first-child {
      padding-top: 90px;
    }
  
  }
  