.player_dashboard .column_player_content {
    background-color: #2f4369;
    color: white;
    box-shadow: 0px 0px 0px 1px rgb(140 140 140/.2);
    border-radius: 5px;
    font-weight: bold;
    padding:0;
}

.player_dashboard .column_player_content + .player_dashboard .column_player_content {
    border: 10px solid red;
}

.player_dashboard .column_player_content img.player_profile_image {
    width: 100px;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    border-radius: 50%;
    border: 1px solid #0d0715;
    border: 1px solid rgba(34, 34, 38, 0.15);
    border-radius: 50%;
}

.player_dashboard .column_player_content img.player_club_logo {
    position: absolute;
    left: 10px;
    width: 55px;
    left: -20px;
    top: 77px;
}

.player_dashboard .column_player_content img.player_club_logo:hover {
    cursor: pointer;
    opacity: .7;
}

.player_dashboard .column_player_content .label_player_position,
.player_dashboard .column_player_content .label_player_name {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: 100%;
}

.player_dashboard .column_player_content .column_player_number {
    height: 80px;
    font-size: 80px;
    line-height: 70px;
    padding-right: 24px;
}

.player_dashboard .column_player_content .label_player_number {
    margin-left: auto;
    margin-right: auto;
    display: block;
    text-align: center;
}

.player_dashboard .column_player_content label.label_player_position {
    font-size: 18px;
    font-weight: 300;
    font-style: italic;
}

.player_dashboard .column_player_content label.label_player_name {
    font-size: 25px;
}

.player_dashboard .column_player_content .row_player_personal_details {
    font-weight: 500;
    font-size:12px;
    margin-top:10px;
}

.player_dashboard .column_player_content .row_player_personal_details .player_country_flag img {
    width: 40px;
    margin-left:auto;
    margin-right:auto;
    display:block;
    position: relative;
}

.player_dashboard .column_player_content .row_player_personal_details .player_nationality_details label {
    display: block;
    width: 100%;
    text-align: center;
    margin-top:auto;
    margin-bottom:auto;
}

.player_dashboard .column_player_personal_information .row_player_personal_information {
    color:white;
    border:0;
    font-weight: 300;
    padding:5px;
    font-size:13px;
}

.player_dashboard .column_player_personal_information .row_player_personal_information span {
    width: auto;
}

.player_dashboard .column_player_personal_information .row_player_personal_information span.information_title {
    padding: 0;
}

.player_dashboard .column_player_personal_information .row_player_personal_information span.information_value {
    font-weight: bold;
}

.player_dashboard .column_player_personal_information .row_player_personal_information span.information_value img.player_national_flag {
    width:25px;
    margin-left:10px;
    position:relative;
    top:-1px;
}

.player_dashboard .column_player_personal_information .row_player_personal_information span.clickable_value:hover {
    cursor: pointer;
    text-decoration: underline 2px white;
}

.player_dashboard .column_player_bio {
    padding-top:20px;
}

.player_dashboard .column_player_bio .paragraph_player_bio {
    text-align: center;
    font-weight: 400;
    font-size: 14px;
}

.statistic_row span.statistic_row_header {
    font-weight: 600;
}

.statistic_row {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size:14px;
}

.statistic_row span:first-child {
    float:left;
}

.statistic_row span:last-child {
    float:right;
}

.player_profile {
    font-size: 14px;
    font-weight: 500;
}

.player_profile .player_profile_heading {
    margin-bottom: 2px;
    color: rgba(34, 34, 38, 0.45);
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    text-transform: uppercase;
}

.player_profile .player_profile_content {
    text-align: center;
    font-weight: 500;
}

.player_profile .player_profile_team_details {
    border-bottom: 1px solid #e5e5e5;
}

.player_profile img.country_flag {
    border-radius: 50%;
}

.mobile_content .player_dashboard .remove_inline_padding_mobile {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

