.home-fixture-controls-row .home-fixture-controls-col .MuiTextField-root .MuiSvgIcon-root{
    font-size:30px !important;
}

.link_competition_select {
    padding-left:0;
    padding-right:0;
}

.column_primary_content {
    padding-top:15px;
}