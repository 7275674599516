.mobile-pitch-container {
    height: 370px;
    padding-top: 15px;
    padding-bottom: 14px;
}

.pitch-container {
    justify-content: space-between;
    background-image: url('../../../../images/fixture-pitch.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
    min-height: 715px;
    padding: 14px 8px 10px 8px;;
}

.team-wrapper {
    position: relative;
    flex: 1 1 368px;
    border-bottom: none;
    display: flex;
    height: 360px;
}

.formation-team {
    z-index: 1;
    flex-direction: column;
    flex: 1;
    border-bottom: none;
    display: flex;
}

.formation-row {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    flex: 1 1 auto;
}

.formation-row-away {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    justify-content: space-around;
    flex-direction: row-reverse;
    flex: 1 1 auto;
}

.formation-row .player, .formation-row-away .player {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: start;
    -webkit-box-align: center;
    align-items: center;
    position: relative;
    z-index: 1;
    border-radius: 4px;
    text-align: center;
    cursor: pointer;
    transition: opacity 0.1s ease-in-out;
    width: 56px;
    height: 72px;
    justify-content: center;
}

.formation-row .player:not(.home-goalkeeper, .away-goalkeeper) {
    justify-content: flex-start;
}

.formation-row-away .player:not(.home-goalkeeper, .away-goalkeeper) {
    justify-content: flex-start;
}

.formation-row .player img, .formation-row-away .player img {
    object-fit: cover;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 
                0px 1px 1px 0px rgba(0, 0, 0, 0.14), 
                0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    position: relative;
    top: 0px;
    border: 2px solid #ffffff;
}

.formation-row .player span.player-name, .formation-row-away .player span.player-name {
    color: #000000 !important;
    font-weight: 500;
    width: 75px;
    top: 40px;
    font-size: 12px;
    line-height: 12px;
    text-align: center;
    overflow: hidden;
    overflow-wrap: break-word;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    hyphens: auto;
    margin-top: 3px;
    height: auto;
    font-weight: 600;
    font-feature-settings: "ss01";
    box-sizing: border-box;
    font-variant: all-petite-caps;
}

.formation-row .player span.player-rating, .formation-row-away .player span.player-rating {
    color: #fff;
    font-weight: 600;
    font-size: 11px;
    height: 13px;
    left: 36px;
    position: absolute;
    text-align: center;
    vertical-align: top;
    width: 20px;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    align-items: center;
    display: flex;
    text-align: center;
    justify-content: center;
}

.formation-row .player:not(.home-goalkeeper) span.player-rating, .formation-row-away .player span.player-rating {
    top: 27px;
}

.formation-row .player.home-goalkeeper span.player-rating, .formation-row-away .player.away-goalkeeper span.player-rating {
    top: 35px;
}

.formation-row .player span.player-goals, .formation-row-away .player span.player-goals {
    left: -2px;
    top: 25px;
    font-weight: 500;
    font-size: '18px';
    position: absolute;
    display: flex;
    border-radius: 50%;
    background-color: white;
    outline: 1px solid white;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.formation-row .player span.yellow-card, 
.formation-row-away .player span.yellow-card,
.formation-row .player span.red-card, 
.formation-row-away .player span.red-card,
.formation-row .player span.yellow-red-card, 
.formation-row-away .player span.yellow-red-card {
    width: 13px;
    height: 17px;
    border-radius: 2px;
    margin-left: 3px;
    position: absolute;
    top: -4px;
    left: 0;
    box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.formation-row .player span.yellow-card, 
.formation-row-away .player span.yellow-card {
    background-color: #d3ad02;
}

.formation-row .player span.red-card, 
.formation-row-away .player span.red-card,
.formation-row .player span.yellow-red-card, 
.formation-row-away .player span.yellow-red-card {
    background-color: #dd3c23;
}