table.table_player_statistic_insights {
    position: relative;
}

table.table_player_statistic_insights img {
    width: 34px;
    aspect-ratio: auto 34 / 34;
    height: 34px;
}

table.table_player_statistic_insights tr td.player_name {
    width:150px;
    white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
}

table.table_player_statistic_insights tr td:not(:first-child, .player_name) {

    min-width: 53px;

}

table.table_player_statistic_insights td.player_name {
    font-weight: 500;
}

.table_player_statistic_insights .insight-0, 
.statistic_flex_div .insight-0,
.table_player_statistic_insights .insights_substituted_1, 
.statistic_flex_div .insights_substituted_1
{
    background-color: #f0f0f0;
}

.table_player_statistic_insights .insight-1, 
.statistic_flex_div .insight-1,
.table_player_statistic_insights .insights_substituted_2, 
.statistic_flex_div .insights_substituted_2
{
    background-color: #ADD8E6;
}

.table_player_statistic_insights .insight-2,
.statistic_flex_div .insight-2,
.table_player_statistic_insights .insights_substituted_2, 
.statistic_flex_div .insights_substituted_2
{
    background-color: #8AC7DB;
}

.table_player_statistic_insights .insight-3, 
.statistic_flex_div .insight-3,
.table_player_statistic_insights .insights_substituted_3, 
.statistic_flex_div .insights_substituted_3
{
    background-color: #67B7D1;
}

.table_player_statistic_insights .insight-4, 
.statistic_flex_div .insight-4,
.table_player_statistic_insights .insights_substituted_4, 
.statistic_flex_div .insights_substituted_4 
{
    background-color: #43A6C6;
}

.table_player_statistic_insights .insight-5, 
.statistic_flex_div .insight-5,
.table_player_statistic_insights .insights_substituted_5, 
.statistic_flex_div .insights_substituted_5
{
    background-color: #338BA8;
}

.table_player_statistic_insights .high-insight, 
.statistic_flex_div .high-insight 
{
    background-color: #296E85;
}

.statistic_flex_div {
    display: flex;
    flex-direction: row;   
    width:100%;
    /* justify-content: space-evenly; */
}

.statistic_flex_div:not(:last-child) {
    border-bottom: 1px solid #ffffff;
}

.statistic_flex_div div:nth-child(2) 
{
    min-width: 190px;
    max-width: 190px;
    width:190px;
}

.statistic_flex_div div:nth-child(n+3) {
    width: 79px;
    min-width: 79px;
    max-width: 79px;
    text-align: center;
    font-size: 12px;
}

.statistic_flex_div div {
    padding-top: 10px;
    padding-bottom: 10px;
    align-items: center;
    display: flex;
}

.statistic_flex_div div:first-child {
    width: 50px;
    padding-left: 10px;
    padding-right: 10px;
}

.statistic_flex_div div span {
    flex: auto;
}