h2 {
    margin: 0px 0px 4px;
    font-size: 24px;
}

.heading_column {
    margin: 20px;
    padding-left: 10px;
    padding-right: 10px;
}

.heading_column .heading_column_image {
    padding:0;
    width:60px;
    height:60px;
}

.heading_column .heading_column_image img {
    width:100%;
    height:100%;
}

.heading_column_sub_title:hover {
    text-decoration: 2px solid black underline;
    cursor: pointer;
}

.heading_column .heading_column_sub_title img {
    width:25px;
    height:25px;
    margin-right: 10px;
}

.heading_column .heading_column_title {
    -webkit-box-flex: 1;
    flex-grow: 1;
}