.team_information_header {
    padding:16px 12px;
    display: flex;
    flex-wrap: wrap;

    background-color: #2f4369;
    color: white;
    box-shadow: 0px 0px 0px 1px rgb(140 140 140/.2);
    border-radius: 5px;
}

.team_information_header .name_container {
    padding: 0px 12px
}

.team_information_header .name_container .team_name {
    flex: 1 1 auto;
    margin: 0;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-size: 32px;
    font-weight: 500;
}

.team_information_header .name_container .country_details img.country_flag {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    margin-right: 5px;
}

.team_information_header .team_information_header .logo_container {
    margin-left: 8px;
    margin-right: 24px;
}

.team_information_header .logo_container img.team_profile_logo {
    width: 80px;
    height: 80px;
}